<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="modal-content">
		<div class="contest-task">
			<div class="modal-title sm">
				{{ payload.status === 'edit' ? 'Прохождение конкурса' : 'Просмотр результата' }}
			</div>
			<div
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					{{ payload.status === 'edit' ? 'Текущий этап конкурса:' : 'Этап:' }}
				</h4>
				<div class="text dark sm">
					{{ actualStage.name }}
				</div>
			</div>
			<div
				v-if="selectedNominations.length > 0"
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Номинация:
				</h4>
				<div
					class="field-questionary"
				>
					<c-select
						:key="chosenNomination"
						:name="'Выбрать номинацию'"
						autosearch-nomination
						is-onboarding
						is-courses
						:default-data="selectedNomination"
						:options="selectedNominations"
						:is-error="$v.form.nomination_id.$dirty && !$v.form.nomination_id.required"
						@selected="selectNomination"
					/>
				</div>
			</div>
			<div v-else-if="availableNominations.length > 0">
				<h4 class="contest-task__subtitle">
					На данный момент для вашей роли отсутствуют активные номинации.
				</h4>
			</div>
			<div v-else-if="availableNominations.length === 0">
				<h4 class="contest-task__subtitle">
					Вы не можете пройти задания на этом этапе.
				</h4>
			</div>
			<div
				v-if="nominationStatus !== 0"
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Статус:
				</h4>
				<div
					class="text sm"
					:class="{
						'text--info': nominationStatus === 1 || nominationStatus === 2 || nominationStatus === 4 || nominationStatus === 6 || nominationStatus === 7,
						'text--success': nominationStatus === 8,
						'text--error': nominationStatus === 5,
						'text--attention': nominationStatus === 3,
						'text--neutral': nominationStatus === -1 || nominationStatus === 9,

					}"
				>
					{{ requestStatuses.find((item) => item.value === nominationStatus).label }}
				</div>
			</div>
			<div
				v-if="nomantionScore"
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Количество баллов:
				</h4>
				<div class="event-application__text textual">
					{{ nomantionScore }}
				</div>
			</div>
			<div
				v-if="nominationCommentRevision"
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Комментарий для доработки:
				</h4>
				<div class="event-application__text textual">
					{{ nominationCommentRevision }}
				</div>
			</div>

			<div
				v-if="isSelectedNomination && nominationDescription"
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Описание:
				</h4>
				<div
					class="event-application__text textual"
					v-html="nominationDescription"
				/>
			</div>
			<div>
				<div v-if="isSelectedNomination && actualTask.task_field_groups && actualTask.task_field_groups.length > 0">
					<div v-if="payload.status === 'edit'">
						<div class="modal-title sm">
							Выполнение задания: {{ actualTask.name }}
						</div>
						<p class="contest-task__subtitle red">
							Поля отмеченные * обязательны для заполнения
						</p>
						<div
							v-if="actualTask.task_field_groups.length > 1"
							class="program-items"
						>
							<div
								v-for="(group, groupIdx) in actualTask.task_field_groups"
								:key="group.id"
								:class="{
									'program-item': true,
									'program-item--active': isOpenGroup[groupIdx]
								}"
							>
								<div
									class="program-item__toggle"
									@click="toggleProgramItem(groupIdx)"
								>
									<div class="program-item__index">
										{{ groupIdx + 1 }}
									</div>
									<div class="program-item__title">
										{{ group.name }}
									</div>
								</div>
								<div
									class="program-item__content"
									:style="isOpenGroup[groupIdx] ? 'display: block' : 'display: none'"
								>
									<div class="program-item__text">
										{{ group.description }}
									</div>
									<div class="materials">
										<div
											v-for="(task) in group.task_fields"
											:key="task.id"
										>
											<div
												v-if="task.type === 0 || task.type === 2"
												class="contest-task__field"
											>
												<h4 class="contest-task__subtitle">
													{{ task.name }}: <span
														v-if="task.is_required"
														class="red"
													>*</span>
												</h4>
												<h4
													class="contest-task__description"
													v-html="task.explanation"
												/>
												<div
													class="field-simple"
													:class="{'field-simple--error': !inputTasks[task.id] && !isDraft && task.is_required && isLoading}"
												>
													<input
														v-model="inputTasks[task.id]"
														:type="getType(task.type)"
														class="field-simple__value"
														placeholder="Введите ответ"
														@change="inputChange(task, inputTasks[task.id])"
													>
													<div
														v-if="!inputTasks[task.id] && task.is_required && isLoading && !isDraft"
														class="field-simple__error"
													>
														Обязательно для заполнения
													</div>
												</div>
											</div>
											<div
												v-if="task.type === 1"
												class="contest-task__field"
											>
												<h4 class="contest-task__subtitle">
													{{ task.name }}: <span
														v-if="task.is_required"
														class="red"
													>*</span>
												</h4>
												<h4
													class="contest-task__description"
													v-html="task.explanation"
												/>
												<div
													class="field-simple"
													:class="{'field-simple--error': !inputTasks[task.id] && task.is_required && isLoading && !isDraft}"
												>
													<input
														v-model="inputTasks[task.id]"
														type="date"
														class="field-simple__value"
														@change="inputChange(task, inputTasks[task.id])"
													>
													<div
														v-if="!inputTasks[task.id] && task.is_required && isLoading && !isDraft"
														class="field-simple__error"
													>
														Обязательно для заполнения
													</div>
												</div>
											</div>
											<div
												v-if="task.type === 3"
												class="contest-task__field"
											>
												<h4 class="contest-task__subtitle">
													{{ task.name }}: <span
														v-if="task.is_required"
														class="red"
													>*</span>
												</h4>
												<h4
													class="contest-task__description"
													v-html="task.explanation"
												/>
												<UploadFile
													:key="componentKey"
													:file-name="task.name"
													:file-size="task.field_data.file_size ? task.field_data.file_size : 100"
													:accept-files="task.field_data.accept_file"
													:required="task.is_required"
													:multi-count="task.field_data.multi_count"
													:is-loading="isLoading"
													:old-file="filesTask[task.id]"
													@uploaded="uploadFiles"
												/>
											</div>
											<div
												v-if="task.type === 4"
												class="contest-task__field"
											>
												<h4 class="contest-task__subtitle">
													{{ task.name }}: <span
														v-if="task.is_required"
														class="red"
													>*</span>
												</h4>
												<h4
													class="contest-task__description"
													v-html="task.explanation"
												/>
												<div class="field-questionary">
													<c-select
														:key="componentKey"
														:name="task.name"
														autosearch-nomination
														is-contest-task
														is-onboarding
														is-courses
														:default-data="selectedTask[task.id]"
														:options="task.field_data.selectFields"
														:is-contest-personal-choice="task.field_data.isFieldPersonalChoice"
														:is-error="isLoading && !selectedTask.length && !isDraft && task.is_required"
														@selected="selectTask"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<div
								v-for="(task) in actualTask.task_field_groups[0].task_fields"
								:key="task.id"
							>
								<div
									v-if="task.type === 0 || task.type === 2"
									class="contest-task__field"
								>
									<h4 class="contest-task__subtitle">
										{{ task.name }}: <span
											v-if="task.is_required"
											class="red"
										>*</span>
									</h4>
									<h4
										class="contest-task__description"
										v-html="task.explanation"
									/>
									<div
										class="field-simple"
										:class="{'field-simple--error': !inputTasks[task.id] && !isDraft && task.is_required && isLoading}"
									>
										<input
											v-model="inputTasks[task.id]"
											:type="getType(task.type)"
											class="field-simple__value"
											placeholder="Введите ответ"
											@change="inputChange(task, inputTasks[task.id])"
										>
										<div
											v-if="!inputTasks[task.id] && task.is_required && isLoading && !isDraft"
											class="field-simple__error"
										>
											Обязательно для заполнения
										</div>
									</div>
								</div>
								<div
									v-if="task.type === 1"
									class="contest-task__field"
								>
									<h4 class="contest-task__subtitle">
										{{ task.name }}: <span
											v-if="task.is_required"
											class="red"
										>*</span>
									</h4>
									<h4
										class="contest-task__description"
										v-html="task.explanation"
									/>
									<div
										class="field-simple"
										:class="{'field-simple--error': !inputTasks[task.id] && task.is_required && isLoading && !isDraft}"
									>
										<input
											v-model="inputTasks[task.id]"
											type="date"
											class="field-simple__value"
											@change="inputChange(task, inputTasks[task.id])"
										>
										<div
											v-if="!inputTasks[task.id] && task.is_required && isLoading && !isDraft"
											class="field-simple__error"
										>
											Обязательно для заполнения
										</div>
									</div>
								</div>
								<div
									v-if="task.type === 3"
									class="contest-task__field"
								>
									<h4 class="contest-task__subtitle">
										{{ task.name }}: <span
											v-if="task.is_required"
											class="red"
										>*</span>
									</h4>
									<h4
										class="contest-task__description"
										v-html="task.explanation"
									/>
									<UploadFile
										:key="componentKey"
										:file-name="task.name"
										:file-size="task.field_data.file_size ? task.field_data.file_size : 100"
										:accept-files="task.field_data.accept_file"
										:required="task.is_required"
										:multi-count="task.field_data.multi_count"
										:is-loading="isLoading"
										:old-file="filesTask[task.id]"
										@uploaded="uploadFiles"
									/>
								</div>
								<div
									v-if="task.type === 4"
									class="contest-task__field"
								>
									<h4 class="contest-task__subtitle">
										{{ task.name }}: <span
											v-if="task.is_required"
											class="red"
										>*</span>
									</h4>
									<h4
										class="contest-task__description"
										v-html="task.explanation"
									/>
									<div class="field-questionary">
										<c-select
											:key="componentKey"
											:name="task.name"
											autosearch-nomination
											is-contest-task
											is-onboarding
											is-courses
											:default-data="selectedTask[task.id]"
											:options="task.field_data.selectFields"
											:is-contest-personal-choice="task.field_data.isFieldPersonalChoice"
											:is-error="isLoading && !selectedTask.length && !isDraft && task.is_required"
											@selected="selectTask"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div>{{ viewTask[0][0].groupId }}</div>
						<div v-if="viewTask && viewTask.length > 1">
							<div class="modal-title sm">
								Выполненое задание "{{ actualTask.name }}"
							</div>
							<div class="program-items">
								<div
									v-for="(group, groupIdx) in viewTask"
									:key="group[0].groupId"
									:class="{
										'program-item': true,
										'program-item--active': isOpenGroup[groupIdx]
									}"
								>
									<div
										class="program-item__toggle"
										@click="toggleProgramItem(groupIdx)"
									>
										<div class="program-item__index">
											{{ groupIdx + 1 }}
										</div>
										<div class="program-item__title">
											{{ group[0].groupName }}
										</div>
									</div>
									<div
										class="program-item__content"
										:style="isOpenGroup[groupIdx] ? 'display: block' : 'display: none'"
									>
										<div class="program-item__text">
											{{ group[0].groupDescription }}
											<div class="materials">
												<div
													v-for="(field) in group[0].tasks"
													:key="field.id"
													class="contest-task__field"
												>
													<h4 class="contest-task__subtitle">
														{{ field.name }}
													</h4>
													<div
														v-if="typeof field.value === 'object'"
														class="contest-task__field"
													>
														<p
															v-for="file in field.value"
															:key="file"
															class="text text--s text--dark"
														>
															<a
																target="_blank"
																:href="file"
															>Открыть файл</a>
														</p>
													</div>
													<p
														v-else-if="field.value === 3"
														class="text text--s text--dark"
													>
														<template v-if="field.value.includes('https')">
															<a
																target="_blank"
																:href="field.value"
															>Открыть файл</a>
														</template>
													</p>
													<template v-else>
														{{ field.value }}
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="viewTask && viewTask.length === 1">
							<div class="modal-title sm">
								Выполненое задание "{{ actualTask.name }}"
							</div>
							<div class="materials">
								<div
									v-for="(field) in viewTask[0][0].tasks"
									:key="field.id"
									class="contest-task__field"
								>
									<h4 class="contest-task__subtitle">
										{{ field.name }}
									</h4>
									<div
										v-if="typeof field.value === 'object'"
										class="contest-task__field"
									>
										<p
											v-for="file in field.value"
											:key="file"
											class="text text--s text--dark"
										>
											<a
												target="_blank"
												:href="file"
											>Открыть файл</a>
										</p>
									</div>
									<p
										v-else-if="field.value === 3"
										class="text text--s text--dark"
									>
										<template v-if="field.value.includes('https')">
											<a
												target="_blank"
												:href="field.value"
											>Открыть файл</a>
										</template>
									</p>
									<template v-else>
										{{ field.value }}
									</template>
								</div>
							</div>
						</div>
						<div
							v-else
							class="text sm"
						>
							Вы не выполняли задание "{{ actualTask.name }}"
						</div>
					</div>
					<div v-if="selectedNomination">
						<button
							v-if="selectedNominations.length > 0 && payload.status === 'edit'"
							type="submit"
							:class="{'button contest-task__button': true,'button--disabled': !isSelectedNomination || (nominationStatus !== 3 && nominationStatus !== 0 && nominationStatus !== -1)}"
							@click="sendingApplication"
						>
							Отправить
						</button>
						<button
							v-if="selectedNominations.length > 0 && payload.status === 'edit' && (nominationStatus === 0 || nominationStatus === -1)"
							type="submit"
							:class="{'button contest-task__button': true,'button--disabled': !isSelectedNomination}"
							@click="saveDraft"
						>
							Сохранить черновик
						</button>
						<button
							v-if="selectedNominations.length > 0 && payload.status === 'edit'"
							type="reset"
							:class="{'button contest-task__button button--hollow': true, 'button--disabled': !isSelectedNomination || (nominationStatus !== 3 && nominationStatus !== 0 && nominationStatus !== -1)}"
							@click="resetApplication"
						>
							Отменить
						</button>
					</div>
					<div v-if="isSelectedNomination && !actualTask.task_field_groups">
						Вы не подавали заявку на эту номинацию
					</div>
				</div>
			</div>
			<div v-if="isCancelModal || isSendModal">
				<CancelModal
					:text="isCancelModal ?
						'Внимание, внесенные изменения будут потеряны, хотите сохранить черновик заявки?' :
						'Внимание! После публикации заявки редактирование будет недоступно. Отправить заявку?'
					"
					@saveToDraft="saveDraft"
					@sendApplication="sendApplication"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import UploadFile from '@/components/contest/UploadFile.vue';
import ContestsAPI from '@/api/contests';
import { mapActions, mapGetters } from 'vuex';
import CancelModal from '@/components/modal/contest/CancelModal.vue';

export default {
    components: {
        CancelModal,
        UploadFile
    },
    data() {
        return {
            form: {
                nomination_id: '',
                criterion_id: '',
                user_files: {},
            },
            chosenNomination: 0,
            isDisabled: true,
            nominations: [],
            actualStage: {},
            availableNominations: [],
            isSelectedNomination: false,
            nominationDescription: '',
            selectedNomination: '',
            nominationStatus: 0,
            nominationCommentRevision: '',
            nomantionScore: 0,
            nominationFiles: {},
            userContestID: null,
            tasks: [],
            actualTask: [],
            selectedTask: [],
            filesTask: [],
            inputTasks: [],
            isLoading: false,
            componentKey: 0,
            isCancelModal: false,
            isSendModal: false,
            isDraft: false,
            stages: [],
            isOpenGroup: {},
            viewTask: null
        }
    },
    validations: {
        form: {
            nomination_id: {
                required
            },
            user_files: {required},
        },
    },
    computed: {
        ...mapGetters('contest', {
            requests: 'getRequests'
        }),
        payload() {
            return this.$store.state.modal.payload;
        },
        ...mapGetters('cnst', {
            userGroup: 'getUserGroup',
            requestStatuses: 'getRequestStatuses'
        }),
        selectedNominations () {
            if (this.stages[0] === this.actualStage) {
                this.nominations.forEach((nom) => {
                    this.availableNominations.forEach((id) => {
                        if (this.requests && this.requests.length > 0) {
                            this.requests.forEach((request) => {
                                if (request.nomination_id === id && request.nomination_id === nom.id && nom.id === id && this.actualStage.id === request.stage_id) {
                                    nom.status = request.status;
                                    nom.user_files = request.user_files;
                                    nom.comment_revision = request.comment_revision;
                                    nom.userContestId = request.id;
                                    nom.score = request.score;
                                }
                            })
                        }
                    })
                })
                return this.nominations;
            }
            else {
                let tmpAvailable = []
                let tmpNotAvailable = []
                this.availableNominations.forEach((id) => {
                    this.nominations.forEach((nom) => {
                        if (id === nom.id) {
                            if (this.requests && this.requests.length > 0) {
                                this.requests.forEach((request) => {
                                    if (request.nomination_id === id && request.nomination_id === nom.id && this.actualStage.id === request.stage_id) {
                                        nom.status = request.status;
                                        nom.user_files = request.user_files;
                                        nom.comment_revision = request.comment_revision;
                                        nom.userContestId = request.id;
                                        nom.score = request.score;
                                        tmpAvailable.push(nom)
                                    }
                                    if (request.nomination_id === id && request.nomination_id === nom.id && this.actualStage.id !== request.stage_id) {
                                        tmpNotAvailable.push(nom)
                                    }
                                })
                            }
                        }
                    })
                })
                if (tmpNotAvailable.length !== 0 && this.availableNominations.length === this.nominations.length) return tmpNotAvailable
                else if (tmpAvailable.length !== 0 && this.availableNominations.length < this.nominations.length) return tmpAvailable;
                return tmpNotAvailable
            }
        },
    },
    async created() {
        await this.getContest();
        await this.getRequests(this.payload.contest);
    },
    methods: {
        ...mapActions('contest', {
            getRequests: 'getContestsRequests'
        }),
        getType (type) {
            if (type === 2) return 'number';
            if (type === 1) return 'date';
            return 'text'
        },
        selectNomination(nomID) {
            this.nominationCommentRevision = '';
            this.nomantionScore = 0;
            this.componentKey += 1;
            this.form.nomination_id = nomID.id;
            this.selectedNomination = nomID.name;
            this.isSelectedNomination = true;
            this.selectedNominations.forEach((nom) => {
                if(nom.id === nomID.id) {
                    this.nominationDescription = nom.description;
                    if (nom.status) this.nominationStatus = nom.status
                    else this.nominationStatus = 0
                    if (nom.user_files)  this.nominationFiles = nom.user_files
                    else this.nominationFiles = []
                    if (nom.comment_revision) this.nominationCommentRevision = nom.comment_revision
										if (nom.score) this.nomantionScore = nom.score;
                    if (nom.userContestId) this.userContestID = nom.userContestId;
                }
            })

            this.actualStage.nomination_task_fields.forEach((field) => {
                if (field.nomination_id === nomID.id) {
                    this.form.criterion_id = field.criterion_id
                    this.tasks.forEach((task) => {
                        if (task.id === field.task_id) this.actualTask = task
                    })
                }
            })
            if (Object.keys(this.nominationFiles).length > 0) {
                let tmp = []
                this.actualTask.task_field_groups.forEach((group) => {
                    group.task_fields.forEach((task) => {
                        for (const [key, value] of Object.entries(this.nominationFiles)) {
                            if (task.name === key) {
                                tmp.push({
                                    ...task,
                                    groupId: group.id,
                                    groupName: group.name,
                                    groupDescription: group.description,
                                    value: value
                                });
                                if (task.type === 0 || task.type === 1 || task.type === 2) {
                                    this.inputTasks[task.id] = value;
                                }
                                if (task.type === 3) {
                                    this.filesTask[task.id] = value;
                                }
                                if (task.type === 4) {
                                    this.selectedTask[task.id] = value;
                                }
                            }
                        }
                    });
                });

                const groupedTasks = tmp.reduce((acc, task) => {
                    const group = acc.find(group => group.groupId === task.groupId);

                    if (group) {
                        group.tasks.push(task);
                    } else {
                        acc.push({
                            groupId: task.groupId,
                            groupName: task.groupName,
                            groupDescription: task.groupDescription,
                            tasks: [task] });
                    }

                    return acc;
                }, []);

                groupedTasks.sort((a, b) => a.groupId - b.groupId);
                this.viewTask = groupedTasks.map(group => [group]);
                this.form.user_files = this.nominationFiles
            } else {
                this.inputTasks = []
                this.filesTask = []
                this.selectedTask = []
                this.form.user_files = {}
            }
        },
        uploadFiles(uploadedObj) {
          this.form.user_files = Object.assign( this.form.user_files, uploadedObj );
        },
        selectTask(value) {
            let obj = {};
            this.actualTask.task_field_groups.forEach((group) => {
                group.task_fields.forEach((task, index) => {
                    if (value.index !== index) index++
                    if (task.type === 4 && value.index === index && value.name === task.name) {
                        this.selectedTask[task.id] = value.text;
                        let keyName = value.name;
                        obj[keyName] = this.selectedTask[task.id];
                    }
                })
            })
            this.form.user_files = Object.assign( this.form.user_files, obj );
        },
        inputChange(task, value) {
            let keyName = task.name;
            let obj = {};
            obj[keyName] = value
            this.form.user_files = Object.assign( this.form.user_files, obj );
        },
        async sendApplication () {
            this.isDraft = false
            this.isSendModal = false
            this.isLoading = true
            localStorage.removeItem('selectedPersonalChoice')
            let tmp = []
            this.actualTask.task_field_groups.forEach((group) => {
                group.task_fields.forEach((field, index) => {
                    if (field.is_required && field.position === index) tmp.push(this.form.user_files[field.name]);
                })
            })
            let isSending = tmp.filter((field) => field !== undefined)

            if (isSending.length === tmp.length) {
                this.isDisabled = false;
                this.isLoading = false;

                try {
                    if (this.nominationStatus === 3) {
												// this.form.user_files = this.uploaded_files;
                        await ContestsAPI.sendReworkApplication(this.userContestID, this.form);
                    } else {
                        await ContestsAPI.sendApplication(this.payload.contest, this.actualStage.id, this.form)
                    }
                    this.modalOpen('contest-ok', 'Благодарим за участие! Ваша заявка успешно отправлена.');
                } catch (error) {
                    this.modalOpen('stdFail', 'Задание на эту номинацю уже было отправлено');
                }
            } else {
                this.actualTask.task_field_groups.forEach((group, index) => {
                    this.$set(this.isOpenGroup, index, true);
                });
            }
        },
        async saveDraft () {
            this.isCancelModal = false
            this.isLoading = true
            this.isDraft = true
            let tmp = []
            this.actualTask.task_field_groups.forEach((group) => {
                group.task_fields.forEach((field) => {
                    if (field.is_required) tmp.push(this.form.user_files[field.name]);
                })
            })

            this.isDisabled = false;
            this.isLoading = false;
            try {
                await ContestsAPI.saveDraft(this.payload.contest, this.actualStage.id, this.form)
                this.modalOpen('contest-ok', 'Ваша заявка сохранена в черновике.');
                this.isDraft = false
            } catch (error) {
                this.modalOpen('stdFail', 'Задание на эту номинацю уже в черновике ');
                this.isDraft = false
            }
        },
        resetApplication() {
            this.isCancelModal = !this.isCancelModal
        },
        sendingApplication() {
            this.isSendModal = !this.isSendModal
        },
        async getContest() {
            ContestsAPI.getContestInfo(this.payload.contest).then(response => {
                this.stages = response.data.stages;
								// Находим актуальный этап
                response.data.stages.forEach((stage) => {
                    if (this.payload.status === 'edit') {
                        if ((new Date(stage.end_date).toISOString().split('T')[0] >=
                            new Date().toISOString().split('T')[0]) &&
                            (new Date(stage.start_date).toISOString().split('T')[0] <=
                            new Date().toISOString().split('T')[0])) {
                            this.actualStage = stage
                        }
                    }
                    else if (stage.id === this.payload.stage) {
                        this.actualStage = stage;
                    }
                })
								// Заполняем номинации в зависимости от роли
                response.data.nominations.forEach((item) => {
                    let userRole = '';
                    this.userGroup.forEach((group) => {
                        if (group.label === localStorage.getItem('userRole')) userRole = group
                    })
                    item.user_groups.forEach((user_group) => {
                        if (user_group === userRole.value) {
                            this.nominations.push(item);
                        }
                    })
                })
                this.tasks = response.data.tasks;
                // Получаем доступные номинации для текущего этапа
                if (this.actualStage.id) {
                    this.getAvailableNominations(this.payload.contest, this.actualStage.id);
                }
            });
        },
        async getAvailableNominations(contestId, currentStageId) {
            try {
                const response = await ContestsAPI.getContestNominations(contestId, currentStageId);
                this.availableNominations = response.data.nominations_id;
            } catch (error) {
                console.log(error);
            }
        },
        toggleProgramItem(index) {
            this.$set(this.isOpenGroup, index, !this.isOpenGroup[index]);
        },
    },
}
</script>

<style>
.contest-task__description {
    margin-bottom: 10px;
    font-family: 'Merriweather', serif;
    font-size: 14px;
    font-weight: 400;
}
.contest-task__description ul, .contest-task__description ol {
    display: block;
    list-style-type: revert;
}
.red {
    color: #CF2B34
}
</style>
